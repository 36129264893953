import React, { useEffect, useRef, useState } from "react";
import {
  SafeAreaView,
  View,
  Text,
  Image,
  Dimensions,
  StyleSheet,
  Modal,
  ScrollView,
  Pressable,
} from "react-native";
import { Tooltip } from "react-native-elements";
import { ChargerAttributes } from "../models/charger-model";

const { width, height } = Dimensions.get("window");

const MyChargerInfoCard = (props:{
  index: number, 
  data: ChargerAttributes, 
  editCharger: (chargerDetails: any) => void,
}) => {

  return(
    <View className='mt-4 rounded-lg' key={props.index} style={{backgroundColor: '#292D33'}}>
        <View className='flex flex-row items-center justify-between p-3'>
            <View className='flex flex-row items-center gap-4'>
                <Image
                    source={require('../../assets/chargerStation.png')}
                    style={{width: 50, height: 50}}
                />
                <View>
                    <Tooltip popover={
                        <Text style={{color:"#ffff"}}>
                            {props.data?.name ? props.data?.name : 'Ski and Stay'}
                        </Text>}
                        containerStyle={styles.tooltipContainer}
                        backgroundColor="#0b1e1dd6"
                        >
                        <Text style={[styles.alignEllipses]} className='text-base font-bold text-white'>{props.data?.name ? props.data?.name : 'Ski and Stay'}</Text>
                    </Tooltip>

                    <Tooltip 
                    popover={
                        <Text style={{color:"#ffff"}}>
                            {`${props.data?.street_name  ? props.data?.street_name : "Street"} ${props.data?.city ? props.data?.city : "City"} ${props.data?.state ? props.data?.state : "State"} ${props.data?.country ? props.data?.country : "Country"}`}
                        </Text>}
                        containerStyle={styles.tooltipContainer}
                        backgroundColor="#0b1e1dd6">
                        <Text style={[styles.textColor3, styles.alignEllipses]} className='text-xs font-medium'>{`${props.data?.street_name  ? props.data?.street_name : "Street"} ${props.data?.city ? props.data?.city : "City"} ${props.data?.state ? props.data?.state : "State"} ${props.data?.country ? props.data?.country : "Country"}`}</Text>   
                    </Tooltip>
                </View>
            </View>
            <View>
            { 
                props.data?.isOccupied === 0 && (props.data.status === 'complete' || props.data.status === null) ? 
                <Text 
                    style={styles.BgColor} className='w-full px-6 py-2 text-white rounded-full' 
                >
                    Available
                </Text> :
                <Text style={styles.BgColor1} className='px-4 py-2 text-white rounded-full'>
                    Unavailable
                </Text>
            }
            </View>
        </View>
        <View className='flex flex-row items-center justify-between p-3'>
            <View className='flex flex-row items-center gap-3'>
                <Image
                    source={require('../../assets/chargerType.png')}
                    style={{width: 33, height: 33}}
                />
                <View>
                    <Text className='text-xs font-normal text-white' style={styles.TextColor1}>{`${props.data?.charger_model} - ${props.data?.charger_type} Charger`}</Text>
                    <Text className='text-lg font-semibold text-white'>{`$ ${props.data?.price_kwh_peak}/kWh (pk)`}.</Text>
                </View>
            </View>
        </View>
        <View className="flex flex-row items-center justify-between px-6 py-3">
            <Pressable className="border border-white rounded-full" onPress={()=> props.editCharger(props.data)}>
                <Text className="px-5 py-3 text-xs font-semibold text-white rounded-full">Edit Charger</Text>
            </Pressable>
            <Pressable>
                <Text 
                    className="px-5 py-3 text-xs font-semibold text-white rounded-full"
                    style={styles.BgColor3}>Charger History</Text>
            </Pressable>
        </View>
    </View>
  )
}

export default MyChargerInfoCard;

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#0B1E1D",
  },
  containerBgColor: {
    backgroundColor: "#0B1E1D",
  },
  BgColor: {
    backgroundColor: "#51B868",
  },
  BgColor1: {
    backgroundColor: "#39BEB780",
  },
  BgColor2: {
    backgroundColor: "#F97777",
  },
  BgColor3: {
    backgroundColor: "#39BEB7",
  },
  BgColor4: {
    backgroundColor: "#636363",
  },
  TextColor1: {
    color: "#E8E8E9",
  },
  textColor3: {
    color: "#8D8E90",
  },
  line: {
    borderBottomColor: "#636363",
    borderBottomWidth: 1,
    borderStyle: "dashed",
    marginVertical: 20,
  },
  filterIcon: {
    height: 17,
    width: 17,
    backgroundColor: "#39BEB7",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "#292D33",
    borderRadius: 30,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: 290,
    height: 250,
  },
  dropdownTrigger: {
    width: width <= 430 ? width * 0.4 : 150,
  },
  dropdownContainer: {
    position: "absolute",
    top: 30,
    left: 0,
    backgroundColor: "#fff",
    borderRadius: 5,
    marginTop: 2,
    width: width <= 430 ? width * 0.4 : 150,
    zIndex: 1000,
  },
  alignEllipses: {
    fontWeight: "500",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 150,
  },
  tooltipContainer: {
    borderRadius: 10,
    padding: 10,
    minWidth: 50,
    maxWidth: 500,
    width: "auto",
    height: "auto",
  },
});
