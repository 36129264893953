import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { ApiRequest, ApiResponse, fetchDataFromAPI, fetchDataFromAPITyped } from "../services/api-requests";
import { PaymentAttributes, PaymentDetails } from '../models/payment-model';
  
export const createPayment = async (data: any, orderId: string): Promise<ApiResponse<PaymentAttributes | null>> => {
    const apiPaymentMethodsQuery = PrepareApiProcedure(
      `payments`,
      `POST`,
      'payment',
      {
        paymentMethod: data.paymentMethod,
        orderId: orderId,
        storePaymentMethod: true
      }
      )
    return await fetchDataFromAPITyped<PaymentAttributes>(apiPaymentMethodsQuery)
}

export const getPaymentDetails = async (orderId: string) => {
  const apiQuery: ApiRequest = {
    route: "payments/details",
    request: {
      method: 'GET',
      entity: "payments",
      body: null,
      searchParams: new URLSearchParams({orderId: orderId})
    }
  }
  return await fetchDataFromAPITyped<PaymentDetails>(apiQuery);
}