import AsyncStorage from "@react-native-async-storage/async-storage";

export type ApiRequest = {
  route: string;
  request: {
    method: "GET" | "POST" | "PUT" | "DELETE";
    entity: string;
    body: any;
    searchParams?: URLSearchParams;
  };
};

export type ApiResponse<ResponseType> = {
  status: number | undefined;
  payload: ResponseType;
};

export const isSuccessful = (statusCode: number | undefined) => {
    return statusCode ? statusCode >= 200 && statusCode < 300 : false
}

/**
 * @deprecated - please use fetchDataFromAPITyped instead
 */
export const fetchDataFromAPI = async (apiQuery: any) => {
  console.warn("fetchDataFromAPI: Use fetchDataFromAPITyped instead");
  const typedQuery: ApiRequest = {
    route: apiQuery.api,
    request: {
      method: apiQuery.request.action,
      entity: apiQuery.request.entity,
      body: apiQuery.request.data,
    },
  };
  const response = await fetchDataFromAPITyped<any>(typedQuery);
  return isSuccessful(response.status) ? response.payload : { ...response.payload, errStatus: response.status };
};

export const fetchDataFromAPITyped = async <T,>(
  apiQuery: ApiRequest
): Promise<ApiResponse<T | null>> => {
  let body = undefined
  if (apiQuery.request.body) {
    body = apiQuery.request.body === ""
            ? undefined
            : JSON.stringify(apiQuery.request.body)
  }

  try {
    const route = apiQuery.request.searchParams
      ? apiQuery.route + "?" + apiQuery.request.searchParams.toString()
      : apiQuery.route;
    const jwtToken = await AsyncStorage.getItem("token");
    const IpAddress = await AsyncStorage.getItem("IpAddress");
    const email = await AsyncStorage.getItem("email");
    console.log(apiQuery.request.method + ": " + route);
    const response = await fetch(
      process.env.REACT_NATIVE_API_BASE_URL + route,
      {
        method: apiQuery.request.method,
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          "x-username": `${email}`,
          "x-ip-address": `${IpAddress}`,
          authorization: "Bearer " + jwtToken,
        },
        //credentials: 'include',
        body: body
      }
    );

    // Check if the response has a body
    const contentType = response.headers.get("content-type");
    let data;

    if (contentType && contentType.includes("application/json")) {
      try {
        data = await response.json();
      } catch (err) {
        console.error("Error parsing JSON response:", err);
        return { payload: null, status: undefined}
      }
    } else {
      // Handle cases where the response is not JSON or is empty
      data = await response.text();
      if (!data) {
        console.error("Empty response or non-JSON response");
      }
      return { payload: null, status: response.status }
    }

    return { payload: data, status: response.status };
  } catch (err) {
    console.error("Error in fetchDataFromAPI:", err);
    return { payload: null, status: undefined}
  }
};
