import { getDateInLocalSeconds, getDateInUtcSeconds } from "../components/common/utils/date-time-conversions";
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { ChargerAttributes } from "../models/charger-model";
import { fetchDataFromAPI } from "./api-requests";
import { notificationService } from "./notification-service";

// FUNCTION: getMyChargingHistory
// PURPOSE: Retrieve the charging history for chargers that I have used
export const getMyChargerHistory = async () => {
    try {
        // Fetch charger histories
        const apiQuery = PrepareApiProcedure(
            'charger/getMyChargerHistories/',
            'GET',
            'chargerHistory',
            ''
        );
        const chargingHistoryData = await fetchDataFromAPI(apiQuery);
        return chargingHistoryData;

    } catch (error) {
        console.log(error.message);
        notificationService.sendNotification('error', `Error retrieving your charging history! ${error.message}`);
    }
}

export const getChargerHistoryByTime = async (charger: ChargerAttributes, orderId: string, startTime: Date, endTime: Date, tzOffsetMinutes: number) => {
    const apiQuery = PrepareApiProcedure(
        `charger/chargerHistoryByTime`,
        'POST',
        'chargerHistory',
        {
            chargerId: charger.uuid,
            orderId: orderId,
            timeRangeFrom: getDateInLocalSeconds(startTime, 0), // - (tzOffsetMinutes * 60),
            timeRangeTo: getDateInLocalSeconds(endTime, 0), //  - (tzOffsetMinutes * 60),
            // chargerId: '08375f9c-3d7d-40d1-85bd-3c0a604d2e11',
            // orderId: 'b90577de-a752-40d1-9f3c-204b9d5aeb60',
            // timeRangeFrom: 1731101593,
            // timeRangeTo: 1731159076
        }
    );
    const chargerHistory = await fetchDataFromAPI(apiQuery);
    return chargerHistory;
}

// * FUNCTION: getChargerHistorybyUser
// * PURPOSE: retrieve a user's charging history
export const getChargingHistoryByUser = async (startTime?: Date, endTime?: Date) => {
    try{
        const apiQuery = PrepareApiProcedure(
            `charger/chargerHistoryByUser`,
            'POST',
            'chargerHistory',
            {
                timeRangeFrom: startTime ? getDateInUtcSeconds(startTime) : 0,
                timeRangeTo: endTime ? getDateInUtcSeconds(endTime) : 0,
            }
        );
        const myChargingHistory = await fetchDataFromAPI(apiQuery);
        return myChargingHistory;
    } catch (error) {
        console.error("Error getting user charging history", error);
        notificationService.sendNotification("error", "Error retrieving your charging history");
    }
}