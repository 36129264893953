import { Pressable, Text, View } from "react-native";

const StripeSubmitButton = (
  props = {
    onSubmit: () => {},
  }
) => {
  return (
      <Pressable
        onPress={() => {
          props.onSubmit();
        }}
      >
        <Text className="px-5 py-2 text-md font-bold text-center text-white rounded-full bg-[#3FC67D] my-5">
          Submit
        </Text>
      </Pressable>
  );
};

export default StripeSubmitButton;
