import { useEffect, useRef, useState, useCallback } from "react";
import {
  SafeAreaView,
  View,
  Text,
  Image,
  Dimensions,
  StyleSheet,
  Modal,
  ScrollView,
  Pressable,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";

import activelyChargingIcon from "../../assets/activelyChargingIcon.png";
import connectedNotCharging from "../../assets/connectedNotCharging.png";
import notConnectedOrCharging from "../../assets/notConnectedOrCharging.png";
import {
  checkinCharger,
  getActiveChargeData,
} from "../services/charger-service";
import { notificationService } from "../services/notification-service";
import { updateSelectedCharger } from "../store/actions/charging-actions";
import { ChargerAttributes } from "../models/charger-model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { getCurrentUtcSeconds } from "../components/common/utils/date-time-conversions";
import { checkIsPeak } from "../components/common/utils/peak-off-peak-field";
import { AppDispatch } from "../store";
import { getActiveCharges } from "../store/actions/user-actions";
import { ActiveChargeAttributes } from "../models/active-charges-model";
import { isSuccessful } from "../services/api-requests";

const { width, height } = Dimensions.get("window");

const ChargingState = {
  CONNECTED_AND_CHARGING: 0,
  CONNECTED_AND_NOT_CHARGING: 1,
  DISCONNECTED: 3,
  UNKNOWN: -1,
};

export default function ChargingScreen(props: { navigation: any; route: any }) {
  const [timeElapsed, setTimeElapsed] = useState<number>(0);
  const [kWhCharged, setKWhCharged] = useState<number>(0);
  const [estPrice, setEstPrice] = useState<number>(0);
  const [chargingStatus, setChargingStatus] = useState(ChargingState.UNKNOWN);
  const [activeChargeStatus, setActiveChargeStatus] = useState<any>();
  const startEpochSeconds = getCurrentUtcSeconds();
  const dispatch: AppDispatch = useDispatch();

  const pollTimer = useRef<NodeJS.Timeout | null>(null);
  let timer: NodeJS.Timeout | undefined;

  const selectedCharger: ChargerAttributes = useSelector(
    (state: RootState) => state.chargers.selectedCharger
  ) as ChargerAttributes;

  const activeCharges: ActiveChargeAttributes[] | null = useSelector(
    (state: RootState) => state.user.activeCharges
  );
  const [localActiveCharge, setLocalActiveCharge] =
    useState<ActiveChargeAttributes | null>(null);

  const startTime = useRef<Date>(new Date()); //useRef to persist value across renders

  const pollChargingStatus = async (orderId: string) => {
    return await getActiveChargeData(orderId);
  };

  const handleChargingDone = async (orderId: string) => {
    try {
      const response = await checkinCharger(orderId);
      if (isSuccessful(response.status)) {
        notificationService.sendNotification(
          "success",
          "Charger successfully checked in!"
        );
        updateSelectedChargerAndNavigateToSummary(orderId)
      } else {
        notificationService.sendNotification(
            "error",
            "Error checking in your charger!"
          );
      }
    } catch (e) {
      notificationService.sendNotification(
        "error",
        "Error checking in your charger!"
      );
      //TODO: need to figure out if charger is still attached and charging here,
      // ideally we should just try to shut down the charger from here and keep going
      console.error(e);
    }
    if (pollTimer.current) {
      clearInterval(pollTimer.current);
    }

    if (timer) {
      clearInterval(timer);
    }
  };

  const updateSelectedChargerAndNavigateToSummary = (orderId: string) => {
    if (localActiveCharge?.charger.uuid) {
      dispatch(updateSelectedCharger(localActiveCharge.charger.uuid));
    }
    //TODO: should be a summary screen of the total charge
    const endTime = new Date();
    props.navigation.navigate("ChargeSummary", {
      charger: localActiveCharge!.charger,
      startTime: startTime.current,
      endTime: endTime,
      orderId: orderId,
    });
  }

  // Poll function for charger status
  const pollAndUpdate = async () => {
    let chargeStatus;
    if (localActiveCharge) {
      chargeStatus = await pollChargingStatus(localActiveCharge.order.id);
      setActiveChargeStatus(chargeStatus);

            // Check if the message indicates completion
      if (chargeStatus?.message && chargeStatus?.message === "complete") {
        updateSelectedChargerAndNavigateToSummary(localActiveCharge.order.id); // Call handleChargingDone with the order id
        return; // Exit the function to stop further checks if charging is done
      }

      if (
        chargeStatus.chargerCarData[0].car_charging &&
        chargeStatus.chargerCarData[0].car_connected
      ) {
        setChargingStatus(ChargingState.CONNECTED_AND_CHARGING);
      } else if (chargeStatus.chargerCarData[0].car_connected) {
        setChargingStatus(ChargingState.CONNECTED_AND_NOT_CHARGING);
      } else {
        setChargingStatus(ChargingState.DISCONNECTED);
      }
    } else {
      setChargingStatus(ChargingState.UNKNOWN)
    }
  };

  useEffect(() => {
    // fetch the active charges
    dispatch(getActiveCharges());
  }, []);

  useEffect(() => {
    const startPolling = () => {
      pollAndUpdate();
      pollTimer.current = setInterval(pollAndUpdate, 5000); //set the polling interval
      // pollTimer = setInterval(async () => {
      //     pollAndUpdate()
      // }, 5000)
    };

    const stopPolling = () => {
      if (pollTimer.current) {
        clearInterval(pollTimer.current); // Clear the interval
        pollTimer.current = null;
      }
    };

    startPolling(); //only run on component mount

    return () => stopPolling(); //cleanup when component unmounts
  }, [localActiveCharge]);

  useEffect(() => {
    if (activeCharges && activeCharges.length > 0) {
      //console.log("orderId is:", props.route.params.order.id);
      const activeCharge = activeCharges?.find(
        (charge) => charge.order.id === props.route.params.order.id
      );
      console.log("got active charge:", activeCharge);
      if (activeCharge) {
        //console.log("setting active charge:", activeCharge);
        setLocalActiveCharge(activeCharge);
      } else {
        notificationService.sendNotification(
          "error",
          "Unable to locate your charge"
        );
      }
    }
  }, [activeCharges]);

  // Handle polling based on focus state
  useFocusEffect(
    useCallback(() => {
      return () => {
        if (pollTimer.current) {
          clearInterval(pollTimer.current); // Clear interval when screen loses focus
        }
        //update the application state if the charge is active
        dispatch(getActiveCharges());
      };
    }, [])
  );

  useEffect(() => {
    //the activeChargeStatus contains all of the connected and kwh data for the current charge
    if (activeChargeStatus && localActiveCharge) {
      setKWhCharged(activeChargeStatus.pKwh + activeChargeStatus.opKwh);
      setEstPrice(
        activeChargeStatus.peakPrice + activeChargeStatus.offPeakPrice
      );
      setTimeElapsed(
        activeChargeStatus.peakTime + activeChargeStatus.offPeakTime
      );
    }
  }, [activeChargeStatus]);

  // useEffect(() => {
  //     if( timer === null && chargingStatus === ChargingState.CONNECTED_AND_CHARGING){
  //         timer = setInterval(() => {
  //             setTimeElapsed(prevTime => {
  //                 const newTimeElapsed = prevTime + 1
  //                 if(localActiveCharge){
  //                     // Update kwhCharged based on the new timeElapsed
  //                     const newKWhCharged = (newTimeElapsed * (localActiveCharge?.charger.charging_capacity ?? 40)) / 3600;
  //                     setKWhCharged(newKWhCharged);

  //                     // Update estPrice based on the new timeElapsed and whether it's peak time
  //                     const d = new Date();
  //                     const isPeak = checkIsPeak(d, localActiveCharge.charger.peak_selection);
  //                     const newEstPrice = (newTimeElapsed * (isPeak ? localActiveCharge.charger.price_kwh_peak : localActiveCharge.charger.price_kwh_off_peak)/3600);
  //                     setEstPrice(newEstPrice);
  //                 }

  //                  return newTimeElapsed;
  //             });
  //         }, 1000);
  //     }

  //     return() => {
  //         if(timer){
  //             clearInterval(timer);
  //         }
  //     }
  // }, [chargingStatus])

  let status = "Connected and charging";
  let image;

  switch (chargingStatus) {
    case ChargingState.CONNECTED_AND_CHARGING:
      status = "Connected and charging";
      image = activelyChargingIcon;
      break;
    case ChargingState.CONNECTED_AND_NOT_CHARGING:
      status = "Connected and not charging";
      image = connectedNotCharging;
      break;
    case ChargingState.DISCONNECTED:
      status = "Not connected to charger";
      image = notConnectedOrCharging;
      break;
    default:
      status = "Unknown status";
      break;
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <View
        style={[
          styles.containerBgColor,
          { height: height - 65, paddingBottom: 10, flex: 1 },
        ]}
      >
        <ScrollView>
          <View>
            <View className="relative">
              <Image
                source={require("../../assets/ActiveChargingBackground.jpg")}
                style={{ aspectRatio: 1 }}
                alt="Background Image"
              />
              <View
                style={{
                  position: "absolute",
                  bottom: 50,
                  left: "50%",
                  transform: "translateX(-50%)", // Centering horizontally
                  width: 227,
                  height: 74,
                }}
              >
                <Image
                  source={require("../../assets/CarStatic.png")}
                  style={{ width: 227, height: 74 }}
                  alt="car"
                />
                <View className="flex flex-row items-center gap-3">
                  <Image
                    source={require("../../assets/ChargeLocation.png")}
                    style={{ width: 40, height: 40 }}
                    alt="Location"
                  />
                  <View>
                    <Text className="text-base font-bold text-white">{`${localActiveCharge?.charger.name}`}</Text>
                    <Text className="text-sm font-semibold text-white">{`${localActiveCharge?.charger.city},${localActiveCharge?.charger.state}`}</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  position: "relative",
                  flex: 1,
                  alignContent: "center",
                  alignItems: "center",
                  transform: "translateY(85%)", // Centering horizontally
                  width: "auto",
                  height: 74,
                }}
              >
                <Text className="text-sm font-semibold text-white">
                  {`NOTE: Figures are approximate until charge is verified`}
                </Text>
              </View>
            </View>

            <View className="p-4">
              <View className="relative mt-4">
                <Image
                  source={require("../../assets/chargingHistoryBgImage.png")}
                  style={{
                    height: width <= 430 ? width * 0.64 : 270,
                  }}
                  resizeMode="cover"
                />
                <View
                  className="absolute flex flex-row items-center w-full"
                  style={{ height: width <= 430 ? width * 0.35 : 150 }}
                >
                  <View className="items-center flex-1 w-50">
                    <Image
                      source={image}
                      style={{
                        height: 75,
                        width: 75,
                      }}
                    />
                    <Text className="text-xs font-normal text-white">
                      {status}
                    </Text>
                  </View>
                  <View className="items-center flex-1 w-50">
                    <View className="relative">
                      <Image
                        source={require("../../assets/timeElapsedIcon.png")}
                        style={{
                          height: 80,
                          width: 80,
                        }}
                      />
                      <Text
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        className="absolute text-sm font-bold text-white"
                      >
                        {Math.floor(timeElapsed / 60)}:
                        {("0" + (timeElapsed % 60)).slice(-2)}
                      </Text>
                    </View>
                    <Text className="text-xs font-normal text-white">
                      Time elapsed
                    </Text>
                  </View>
                </View>
                <View
                  className="absolute flex flex-row items-center w-full"
                  style={{
                    height: width <= 430 ? width * 0.33 : 140,
                    marginTop: width <= 430 ? width * 0.33 : 140,
                  }}
                >
                  <View className="items-center flex-1 w-50">
                    <View className="flex flex-row items-center gap-3">
                      <Image
                        source={require("../../assets/totalKwhCharged.png")}
                        style={{
                          height: 60,
                          width: 60,
                        }}
                      />
                      <View>
                        <Text className="text-2xl font-bold text-white">
                          {kWhCharged.toFixed(3)}
                        </Text>
                        <Text className="text-xs font-medium text-white">
                          kWh
                        </Text>
                      </View>
                    </View>
                    <Text className="mt-3 text-xs font-normal text-white">
                      Total charged
                    </Text>
                  </View>
                  <View className="items-center flex-1 w-50">
                    <View className="relative">
                      <Image
                        source={require("../../assets/AccruedCostBackground.png")}
                        style={{
                          height: 75,
                          width: 110,
                        }}
                      />
                      <Text
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        className="absolute text-3xl font-medium text-white"
                      >
                        {`$${estPrice.toFixed(2)}`}
                      </Text>
                    </View>
                    <Text className="text-xs font-normal text-white">
                      Est. cost
                    </Text>
                  </View>
                </View>
              </View>

              <View>
                <Text
                  className="mt-4 font-medium text-center text-white"
                  style={{ fontSize: 10 }}
                >
                  To stop charging, unplug the car at any time or press the
                  button below.
                </Text>
              </View>

              <Pressable
                onPress={() => handleChargingDone(props.route.params.order.id)}
              >
                <Text
                  className="p-3 mt-4 text-lg font-bold text-center text-white rounded-full"
                  style={styles.BgColor}
                >
                  Stop Charging
                </Text>
              </Pressable>
            </View>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#0B1E1D",
  },
  containerBgColor: {
    backgroundColor: "#0B1E1D",
  },
  BgColor: {
    backgroundColor: "#F75555",
  },
  BgColor1: {
    backgroundColor: "#39BEB780",
  },
  BgColor2: {
    backgroundColor: "#F97777",
  },
  BgColor3: {
    backgroundColor: "#39BEB7",
  },
  TextColor1: {
    color: "#E8E8E9",
  },
  textColor2: {
    color: "#A3A5A6",
  },
  textColor3: {
    color: "#8D8E90",
  },
  line: {
    borderBottomColor: "#636363",
    borderBottomWidth: 1,
    borderStyle: "dashed",
    marginVertical: 20,
  },
});
