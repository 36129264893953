import React, { useState, useEffect, FC, useRef } from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import { Input } from 'react-native-elements'
import {GooglePlacesAutocomplete, Place} from 'react-native-google-places-autocomplete';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

// const GooglePlacesInput = ({ onPlaceSelect: any }) => {
const GooglePlacesInput: FC<{ onPlaceSelect: (data: Place, details: any) => void, value: any}> = ({ onPlaceSelect, value}) => {

    const ref = useRef();

    useEffect(() => {
        if(value){
            ref.current?.setAddressText(value);
        }
    }, [value]);
    return (
        // <ScrollView 
        //     contentContainerStyle={{
        //         flexGrow: 1,
        //         justifyContent: 'center',
        //     }}
        //     showsVerticalScrollIndicator={true}
        // >
        <View style={{ flex: 1}}>
            <GooglePlacesAutocomplete
                ref= {ref}
                disableScroll = {true}
                onFail={(e) => {console.log("Google autocomplete failed:", e)}}
                placeholder='Input Charger Address'
                keyboardShouldPersistTaps='handled'
                onPress={(data, details = null) => {
                    onPlaceSelect(data, details);
                }}
                
                // query={{
                //     key: process.env.REACT_NATIVE_GOOGLE_MAPS_API_KEY,
                //     language: 'en'
                // }}
                textInputProps={{
                    InputComp: Input,
                    // leftIcon: { type: 'font-awesome', name: 'chevron-right'},
                    errorStyle: {color: 'red'},
                    containerStyle: styles.textInputContainer,
                    inputContainerStyle: styles.inputContainer,
                    inputStyle: styles.textInput,
                    autoComplete: "off",
                    autoCorrect: false,
                }}
                enablePoweredByContainer={false}
                keepResultsAfterBlur={false}
                listViewDisplayed={false}
                requestUrl={{
                    useOnPlatform: 'web', // or 'all'
                    url: `${process.env.REACT_NATIVE_API_BASE_URL}gplaces`
                }}
                minLength={4}
                styles={{
                    textInputContainer: styles.textInputContainer,
                    textInput: styles.textInput,
                }}
            />
        </View>
        // </ScrollView>
    )
}

const styles = StyleSheet.create({
    textInputContainer: {
        borderBottomWidth: 0,
        borderTopWidth: 0,
        borderWidth: 0,
        paddingHorizontal: 0,
    },
    inputContainer: {
        borderBottomWidth: 0,
    },
    textInput: {
        borderWidth: 0,
        borderBottomWidth: 0,
        backgroundColor: '#292D33',
        borderRadius: 50,
        color: '#fff'
    },
});


export default GooglePlacesInput;