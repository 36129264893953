import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { ApiRequest, ApiResponse, fetchDataFromAPI, fetchDataFromAPITyped } from "./api-requests";

export const checkinCharger = async (orderId: string): Promise<ApiResponse<null | undefined>> => {
    //check in the charger
    const apiQuery: ApiRequest = {
        route: "charger/checkin",
        request: {
            method: 'POST', 
            entity: 'charger', 
            body: {
                orderId: orderId
            }
        }

    };
    return fetchDataFromAPITyped(apiQuery);
}

export const checkoutCharger = async (orderId: string, email: string) => {
    //Check out the charger using the checkout API
    const d = new Date().getTimezoneOffset();
    const apiQuery: ApiRequest = {
        route: 'charger/checkout',
        request: {
            method: 'POST',
            entity: 'charger',
            body: {
                orderId: orderId,
                tz_offset: d,
                email: email
            }
        }

    }
  
    return await fetchDataFromAPITyped<null>(apiQuery);
    // TODO: Check the response for success here
}
  

// export const getChargerStatus = async (charger: ChargerAttributes) => {
//     try {
//         const apiQuery = PrepareApiProcedure(`charger/getChargerCarData`, 'POST', 'charger', {chargerId: charger.uuid});
//         const chargerStatus = await fetchDataFromAPI(apiQuery);
//         return chargerStatus;
//     } catch (error) {
//         console.log(error);
//     }
// }

export const getActiveChargeData = async (orderId: string) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/getActiveChargeData`, 'POST', 'charger', {orderId: orderId});
        const chargerStatus = await fetchDataFromAPI(apiQuery);
        return chargerStatus;
    } catch (error) {
        console.log(error);
    }
}

export const validateCharger = async (serialNumber: string) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/validate/${serialNumber}`, 'GET', 'charger', '');
        const validCharger = await fetchDataFromAPI(apiQuery);
        return validCharger;
    } catch (error) {
        console.log(error);
    }
}

export const getSingleChargerData = async (chargerId: string) => {
    try {
        const apiQuery = PrepareApiProcedure(
            `charger/getSingleCharger`, 
            'POST', 
            'charger', 
            {
                chargerId: chargerId
            });
        const singleCharger = await fetchDataFromAPI(apiQuery);
        return singleCharger[0];
    } catch (error) {
        console.log(error);
    }
}