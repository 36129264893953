import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet, TextInput, SafeAreaView, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { createUser } from '../models/login-register-model';
import { registerUser } from '../store/actions/user-actions';
import { notificationService } from '../services/notification-service';

const { width, height } = Dimensions.get('window');

const LoginWithAccount = ({navigation}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    const validateEmail = (email:string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleInputChange = (field, value) => {
        let newErrors = { ...errors };
        
        switch (field) {
            case 'email':
                setEmail(value);
                newErrors.email = !value ? 'Please enter your email address.' : validateEmail(value) ? '' : 'Please enter a valid email address.';
                break;
            case 'password':
                setPassword(value);
                newErrors.password = value ? '' : 'Please enter a password.';
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const signIn = async () => {
        const newErrors = { ...errors };

        if (!email) newErrors.email = "Please enter your email address.";
        if (!password) newErrors.password = "Please enter a password.";
        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error !== "")) {
            return;
        }

        const registerObj = createUser({
            email: email,
            password: password,
        });

        const resp = await registerUser(registerObj);

        if (resp && Object.keys(resp).length > 0) {
            notificationService.sendNotification("success", "Success! You may now log in.");
            setTimeout(() => {
                navigation.navigate('LoginScreen');
            }, 2000);
        } else {
            notificationService.sendNotification("error", "There was an issue while logging in, please try again");
        }
    };

    const handleSubmit = () => {
        signIn();
    };

    return (
        <SafeAreaView>
            <View style={[styles.containerBgColor, { height: height - 65, paddingBottom: 10 }]}>
                <ScrollView>
                    <View style={styles.p4}>
                        <Text className='text-2xl font-bold text-center text-white'>Sign into your account</Text>
                        <Text style={styles.subHeaderText} className='mb-5 text-base font-normal text-center'>
                            {`Sign into your account using your login credentials`}
                        </Text>

                        <View>
                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>Email</Text>
                            <TextInput
                                placeholder="Enter your email"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="w-full p-4 text-white"
                                value={email}
                                onChangeText={(value) => handleInputChange('email', value)}
                            />
                            {errors.email ? <Text style={styles.errorText}>{errors.email}</Text> : null}

                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>Password</Text>
                            <TextInput
                                placeholder="Enter your password"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="w-full p-4 text-white"
                                secureTextEntry
                                value={password}
                                onChangeText={(value) => handleInputChange('password', value)}
                            />
                            {errors.password ? <Text style={styles.errorText}>{errors.password}</Text> : null}

                            <Pressable onPress={handleSubmit} style={styles.button} className='p-4 mt-5 text-center rounded-full'>
                                <Text className='text-lg font-medium text-center text-white'>Sign in</Text>
                            </Pressable>
                        </View>
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    containerBgColor: {
        backgroundColor: '#1A1D21',
    },
    p4: {
        padding: 16,
    },
    subHeaderText: {
        color: '#8D8E90',
    },
    BgColor: {
        backgroundColor: '#292D33',
    },
    errorText: {
        color: '#f38787',
        fontSize: 14,
        marginTop: 4,
    },
    button: {
        backgroundColor: '#39BEB7',
    },
});

export default LoginWithAccount;
