import { MaterialIcons } from '@expo/vector-icons';
import React, {useEffect, useRef} from 'react';
import { View, Text, StyleSheet, Image, ImageSourcePropType, Pressable, Animated, Platform } from 'react-native'; 
import { LinearGradient } from 'expo-linear-gradient';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { ActiveChargeAttributes } from '../models/active-charges-model';
import { OrderAttributes } from '../models/order-model';
import { useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../components/MeanderStack';
import { NativeStackNavigationProp, NativeStackScreenProps } from "@react-navigation/native-stack";
import { platform } from 'process';

interface ActiveChargeCardProps {
    imageSource: ImageSourcePropType;
    title: string;
    subtitle: string;
    distance: string;
  }

type ChargingScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'ChargingScreen'>;

const ActiveChargeCard = () => {
    const activeCharges: ActiveChargeAttributes[] | null = useSelector((state: RootState) => state.user.activeCharges);
    const navigation = useNavigation<ChargingScreenNavigationProp>();

    useEffect(() => {
      console.log("activeCharges: ", activeCharges);
    }, [activeCharges]);

    const getElapsedTime = (dateCreated: string) => {
      const createdDate = new Date(dateCreated);
      const now = new Date();
      const diffInMs = now.getTime() - createdDate.getTime(); // Difference in milliseconds
  
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert milliseconds to hours
      const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60)); // Remainder in minutes
  
      return `${diffInHours}hr ${diffInMinutes}min`;
    };

    const glowAnim = useRef(new Animated.Value(0)).current;

  //   useEffect(() => {
  //     Animated.loop(
  //         Animated.sequence([
  //             Animated.timing(glowAnim, {
  //                 toValue: 1,
  //                 duration: 1500,
  //                 useNativeDriver: false, // Border animation requires useNativeDriver to be false
  //             }),
  //             Animated.timing(glowAnim, {
  //                 toValue: 0,
  //                 duration: 1500,
  //                 useNativeDriver: false,
  //             }),
  //         ])
  //     ).start();
  // }, [glowAnim]);

    useEffect(() => {
      Animated.loop(
          Animated.timing(glowAnim, {
              toValue: 1,
              duration: 1500, // Controls the speed of the pulse
              useNativeDriver: false, // shadowOpacity and shadowRadius can't be animated with native driver
          })
      ).start();
    }, [glowAnim]);

    // Platform-specific shadow animations
    const platformStyles = Platform.select({
      ios: {
          // iOS: Interpolating shadowOpacity and shadowRadius for pulsing glow
          shadowOpacity: glowAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [0.3, 0.8],
          }),
          shadowRadius: glowAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [10, 30],
          }),
      },
      android: {
          // Android: Interpolating elevation for pulsing glow
          elevation: glowAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [5, 20],
          }),
      },
  });

  // Web-specific boxShadow
  const webStyle = Platform.OS === 'web' ? {
      boxShadow: glowAnim.interpolate({
          inputRange: [0, 1],
          outputRange: ['0px 0px 10px rgba(57, 190, 183, 0.3)', '0px 0px 30px rgba(57, 190, 183, 0.9)'],
      }),
  } : {};

     // Interpolating shadowOpacity for pulsing glow
    const shadowOpacityInterpolation = glowAnim.interpolate({
      inputRange: [0, 1],
      outputRange: [0.2, 0.9], // Adjust these values for more subtle or intense glow pulsing
    });

    // Interpolating shadowRadius for pulsing glow spread
    const shadowRadiusInterpolation = glowAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [5, 30], // Adjust these values to change how far the glow extends
    });

    const shadowInterpolation = glowAnim.interpolate({
      inputRange: [0, 1],
      outputRange: ['0px 0px 10px rgba(57, 190, 183, 0.3)', '0px 0px 30px rgba(57, 190, 183, 0.9)'], // Web box-shadow
  });

    const handleActiveCharge = () => {
        if(activeCharges !== null){  
          console.log("navigating to ChargingScreen");
          const retOrder: OrderAttributes  = activeCharges[0].order
          navigation.navigate('ChargingScreen', { order: retOrder! })
        }
    };

    return (
      <Animated.View 
          style={[
              styles.cardContainer, 
              platformStyles,
              webStyle // Apply boxShadow inline for web
          ]}
      >
          <Pressable onPress={() => handleActiveCharge()} style={styles.pressableContainer}>
              <Image 
                  source={require("../../assets/chargerStation.png")} 
                  style={styles.image} 
              />
      
              <View style={styles.textContainer}>
                  <Text style={styles.title}>{`Active Charge`}</Text>
                  {activeCharges && (
                      <Text style={styles.subtitle}>
                          {`${activeCharges[0].charger.name}: ${activeCharges[0].charger.city}, ${activeCharges[0].charger.state}`}
                      </Text>
                  )}
              </View>
      
              <View style={styles.timeContainer}>
                  <Text style={styles.timeText}>
                      {activeCharges && getElapsedTime(activeCharges[0].order.createdAt)}
                  </Text>
              </View>
      
              <View style={styles.iconContainer}>
                  <MaterialIcons name="keyboard-arrow-right" size={24} color="white" />
              </View>
          </Pressable>
      </Animated.View>
  );
}

export default ActiveChargeCard;

const styles = StyleSheet.create({
  cardContainer: {
      position: 'absolute',
      width: 365,
      height: 73,
      left: '50%',
      marginLeft: -(365 / 2) + 0.5, // Centers the card
      top: 16,
      backgroundColor: '#0B1E1D',
      borderColor: '#39BEB7',
      borderWidth: 2,
      borderRadius: 16,

      // Default box-shadow for web (initial value)
      shadowColor: Platform.OS === 'ios' ? '#39BEB7' : undefined,
      shadowOffset: Platform.OS === 'ios' ? { width: 0, height: 0 } : undefined, // iOS only
      elevation: Platform.OS === 'android' ? 5 : undefined, // Android only
  },
  pressableContainer: {
    flex: 1, // Ensures the content takes full space of the cardContainer
    justifyContent: 'center', // Center content vertically
    alignItems: 'center', // Center content horizontally
  },
  // Other styles remain the same...
  image: {
      position: 'absolute',
      width: 50,
      height: 50,
      left: 15,
      top: 11.5,
      borderRadius: 42,
  },
  textContainer: {
      position: 'absolute',
      width: 226,
      height: 46,
      left: 75,
      top: 13.5,
      justifyContent: 'center',
  },
  title: {
      width: 226,
      height: 22,
      fontFamily: 'PlusJakartaSans-Regular',
      fontWeight: '700',
      fontSize: 16,
      lineHeight: 22,
      color: '#FFFFFF',
  },
  subtitle: {
      width: 226,
      height: 16,
      fontFamily: 'PlusJakartaSans-Regular',
      fontWeight: '500',
      fontSize: 12,
      lineHeight: 16,
      color: '#8D8E90',
  },
  timeContainer: {
      position: 'absolute',
      width: 67,
      height: 16,
      left: 268,
      top: 28,
      flexDirection: 'row',
      alignItems: 'center',
  },
  timeText: {
      fontFamily: 'PlusJakartaSans-Regular',
      fontWeight: '400',
      fontSize: 12,
      lineHeight: 16,
      color: '#8D8E90',
  },
  iconContainer: {
      position: 'absolute',
      width: 24,
      height: 24,
      left: 335,
      top: 24.5,
  },
  iconInner: {
      width: 5.5,
      height: 11.5,
      backgroundColor: '#FFFFFF',
  },
});
